import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import styled, { keyframes } from 'styled-components';
import Input from '../input/Input';
import InputB from '../input/InputB';
import TextArea from '../input/TextArea';
import Button from '../button/ButtonA';
import { useInView } from 'react-intersection-observer';
import Footer from '../section/Footer';

const Wrapper = styled.div`
  height : fit-content;
  max-width : 1100px;
  margin : 60px auto;
  margin-bottom : 10px;
  background-color : #FFF9F3;
  opacity: 0;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 0.5s forwards;
  @media (max-width: 800px) {
    width : 80vw;
  }
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform : scale(0.8)
  }
  to {
    opacity: 1;
    transform : scale(1)
  }
`;

const Content = styled.div`
  padding : 50px 70px;
  h2{
    font-weight : 100;
    font-size : 28px;
    color : #212737;
  }
  p{
    color: #393838;
  }
  @media (max-width: 800px) {
    font-size : 14px;
    padding : 30px;
  }
`
const Form = styled.div`
  display : flex;
  margin-top : 40px;
  position : relative;
  @media (max-width: 1150px) {
    flex-direction : column !important;
  }
`
const Left = styled.div`
`
const Center = styled.div`
  width : fit-content;
  margin : 2px auto;
`
const Flex = styled.div`
  width : 500px;
  display : flex;
  justify-content : space-between;
  margin-bottom : 10px;
  @media (max-width: 1150px) {
    flex-direction : column !important;
  }
`
const Right = styled.div`
  position : absolute;
  right : 0;
  @media (max-width: 1150px) {
    position : relative;
    text-align : center;
  }
`
const Relative = styled.div`
  position : relative;
  width : fit-content;
  margin : auto;
`
const Absolute = styled.div`
`
function Contact() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [message, setMessage] = useState(null);  

  const sendEmail = () => {
    if(name != null && email != null && phone != null && message != null){
      emailjs.send('service_zuzxfjp', 'template_29qmsii', {name:name, email: email, phone:phone, message:message},'mt_Q-kuLe1BN8RRex')
        .then((result) => {
          alert("Your Enquiry Has Been Sent")
        }, (error) => {
          console.error(error.text);
        });
    }
    else{
      alert("Please fill all the required fields.")
    }
  };

  return (
    <Relative>
      <Wrapper ref={ref} inView={inView}>
        <Content>
          <h2>Your Business, With Us</h2>
          <p>Talk to our expert consultants today and see how our extensive industry experience and strong production capabilities meet your diverse market demands.</p>
          <Form>
            <Left>
              <Flex>
                <Input label={"*Your Name"} name="name" onChange={(e)=>{setName(e.target.value)}} />
                <Input label={"*Your Email"} name="email" onChange={(e)=>{setEmail(e.target.value)}} />
              </Flex>
              <InputB label={"*Your Phone"} name="phone" onChange={(e)=>{setPhone(e.target.value)}} />
              <TextArea label={"*Your Message"} name="message" onChange={(e)=>{setMessage(e.target.value)}} />
              <Center>
                <Button text={"Send Enquiry"} onClick={sendEmail} />
              </Center>
            </Left>
            <Right>
              <h2>Contact Info</h2>
              <p>
                contact@forallmanufacturing.com<br/>
                + 977 - 9860074126<br/>
              </p>

              <h2>Visit Us</h2>
              <p>
                Jawalakhel, Lalitpur<br/>
                Bagmati, Nepal<br/>
              </p>
            </Right>
          </Form>
        </Content>
      </Wrapper>
      <Absolute>
        <Footer/>
      </Absolute>
    </Relative>
  );
}

export default Contact;
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import TitleA from '../title/TitleA';
import image1 from '../../assets/images/Clothes/slider/image-1.png';
import image2 from '../../assets/images/Clothes/slider/image-2.png';
import image3 from '../../assets/images/Clothes/slider/image-3.png';
import image4 from '../../assets/images/Clothes/slider/image-4.png';
import arrow from '../../assets/images/icons/arrow.png';
import TitleB from '../title/TitleB';
import { useNavigate } from 'react-router-dom';

const fadeIn = keyframes`
    from {
		opacity: 0;
		transform: translate3d(0, 20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
`;

const Wrapper = styled.div`
  opacity: 0;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 0.8s forwards;
`;

const Slider = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  overflow-y : scroll;

  &&::-webkit-scrollbar {
    display: none !important;
  }
`;


const Cloth = styled.div`
  height: fit-content;
  width: 270px;
  margin-right: 12px;
  cursor: pointer;
  img{
    transition-duration : 0.4s;
  }

  img:hover{
    transform : translateY(-5px) scale(1.01)
  }
`;

const ItemStack = styled.div`
  text-align: right;
  font-size: 12px;
  color: #000000a1;
`;

const ViewMore = styled.div`
  position : relative;
  text-align : center;
  font-size : 14px;
  cursor: pointer;
  div {
    height: 20px;
    width: 20px;
    border: 1px solid #21273744;
    border-radius: 50px;
    margin: 0 30px;
    transition-duration: 0.25s;
    background-color : #212737f2;
  }
  img {
    position: absolute;
    width: 35px;
    margin-top: 26px;
    margin-left: 12px;
    transition-duration: 0.25s;
  }
  span {
    color: #1c223599;
    margin-left: 6px;
  }
  div:hover{
    transform : translateX(5px)
  }

`;

const Sub = styled.div`
  padding : 0 25px;
  padding-top : 85px;
`
const CircleA = styled.div`
    position : absolute;   
    border-radius : 50%;
    top : 9px;
    left : -9px;
    background-color : transparent !important;
    border : 1px solid blue !important;
`
const CircleB = styled.div`
    position : absolute;    
    border-radius : 50%;
    bottom : 32px;
    left :-9px;
    background-color : transparent !important;
    border : 1px solid gold !important;
`

function Collection(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const navigate = useNavigate();

  return (
    <Wrapper ref={ref} inView={inView}>
      {props.title && 
        <TitleA text={props.title} />
      }
      {props.sub && 
        <Sub>
          <TitleB text={props.sub}/>
        </Sub>
      }
      <Slider>
        <Cloth>
          <img src={image1} alt="" />
          <ItemStack>
            <span>Black Jacket</span>
          </ItemStack>
        </Cloth>
        <Cloth>
          <img src={image2} alt="" />
          <ItemStack>
            <span>Woolen Full</span>
          </ItemStack>
        </Cloth>
        <Cloth>
          <img src={image3} alt="" />
          <ItemStack>
            <span>Semi Sweater</span>
          </ItemStack>
        </Cloth>
        <Cloth>
          <img src={image4} alt="" />
          <ItemStack>
            <span>Black Pants</span>
          </ItemStack>
        </Cloth>
        <ViewMore onClick={()=>navigate("/products")}>
          <div>
          </div>
          <span>View More</span>
        </ViewMore>
      </Slider>
    </Wrapper>
  );
}

export default Collection;
import React from 'react'
import styled, { keyframes } from 'styled-components'
import TitleA from '../title/TitleA'
import Paragraph from '../collections/paragraph/Paragraph'
import employeeImage from '../../assets/images/Home/employee.png'
import { useInView } from 'react-intersection-observer'
import EmployeeSlider from '../widget/EmployeeSlider'

const Wrapper = styled.div`
    background-color : #d9da9815;
    opacity: 0;
    animation: ${({ inView }) => (inView ? fadeIn : 'none')} 2s forwards;
    @media (max-width: 800px) {
        height : fit-content;
        padding-bottom : 40px;
    }
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const Content = styled.div`
    margin : 0 60px;
    h1{
        padding-top : 35px;
        margin-bottom : 0;
        margin-left : 0;
    }
    p{
        margin : 0;
    }
    @media (max-width: 500px) {
        p{
            margin : 0;
            width : 90vw;
        }
        margin : 20px;
        text-align : center;
    }
`
const Flex = styled.div`
    display : flex;
    align-items : center;
    @media (max-width: 800px) {
        flex-direction : column;
    }
`
const Picture = styled.div`
    margin : 20px 0;
`
const Quote = styled.div`
    color : #313131;
    padding-top : 10px;
    letter-spacing : 1px;
    width : 100%;
    margin-left : 20px;
    p{
        max-width : 500px;
        margin : 0 auto;
    }
`
function EmployeeTestimonial() {
const { ref, inView } = useInView({
    triggerOnce: true,
    });
  return (
    <Wrapper ref={ref} inView={inView}>
        <Content>
            <TitleA text={"#STORIES FROM OUR FACTORY"}/>
            <Paragraph text={"All our factory employees are survivors of domestic violence who have gained valuable skills. Through empowerment and training, they contribute to our workforce, embodying resilience and determination."}/>
            {/* <Flex>
                <Picture>
                    <img src={employeeImage} alt="" />
                </Picture>
                <Quote><p>“Your bulk order can be processed within 15-20 days. If you have a deadline we’ll do our best to meet it.Your bulk order can be processed within 15-20 days. If you have a deadline we’ll do our best to meet it.”<br/> - Sajneena Thapa</p></Quote>
            </Flex> */}
        </Content>
        <EmployeeSlider/>
    </Wrapper>
  )
}

export default EmployeeTestimonial
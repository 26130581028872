import React from 'react'
import styled from 'styled-components'
import NavBar from '../components/navbar/NavBar'
import BannerImage from '../assets/images/Home/Banner.png'
import AboutImage from '../assets/images/Home/AboutUsOriginal.png'
import Sign1 from '../assets/images/Home/sign1.png'
import Sign2 from '../assets/images/Home/sign2.png'
import TitleA from '../components/title/TitleA'
import Paragraph from '../components/collections/paragraph/Paragraph'
import Footer from '../components/section/Footer'
import Contact from '../components/contact/Contact'
import Logo from '../components/logo/Logo'

const Wrapper = styled.div`
    animation : appear 2s;
    @keyframes appear {
        0%{
            opacity : 0;
        }
        100%{
            opacity : 1;
        }
    }
    h1{
        margin-top : 100px;
        text-align : center;
    }
`
const Banner = styled.div`
    img{
        width : 100vw;
    }
    @media (max-width : 1200px) {
        img{
            height : 0;
            padding-bottom : 40px;
        }
    }
    padding-top : 140px;
    font-size : 45px;
    text-align : center;
    a{
        color: #ad2b2b;
    }
    span{
        color: #b19076;
    }
    small{
        font-size : 18px;
    }
`
const Left = styled.div`
    margin-left : 20px;
    h1{
        margin-left : 0;
        margin-top : 0;
    }
    max-width : 500px;
    
`
const Right = styled.div`
    margin-left : 90px;
    margin-right : 0;
`
const Content = styled.div`
    display : flex;
    margin-top : 50px;
    justify-content : end;
    align-items : center;
    h1{
        text-align : center;
    }
    @media (max-width : 1200px) {
        flex-direction : column;
        ${Left}{
            order : 2;
            width: 90vw;
            margin : auto;
            p{
 
            }
        }
        ${Right}{
            width: fit-content;
            margin : auto;
            @media (max-width : 700px){
                img{
                    width : 90vw;
                }
            }
        }
    }
`

const Signature = styled.div`
    display : flex;
    align-items : center;
    max-width : 350px;
    margin : 50px auto;
`

const Sign = styled.div`
    img{
        height : 80px;
    }
    p {
        color: #0000005f;
        font-size : 14px;
    }
    a{
        color: #000000ca;
        font-size : 16px;
    }
    text-align : center;
    margin : 20px;
`

const LogoSection = styled.div`
    width : fit-content;
    margin : 0px auto;
    img{
        height : 40px;
    }
`
function AboutPage() {
  return (
    <Wrapper>
        <NavBar currentPage={"about"}/>
        <Banner>
            <small>Since 2020,</small><br/>
            Designed In <a>Nepal</a>, Worn <span>Across The World</span>
        </Banner>
                <TitleA text={"ABOUT US"}/>
        <Content>
            <Left>
                <p>
                    Founded in 2019 by two highly motivated young individuals, our journey started with a big dream – launching a clothing brand. We faced challenges finding a manufacturer who understood our vision and could handle flexible order quantities. Determined to overcome these challenges, we took matters into our own hands and started our own factory. This tough time became the starting point for our unique perspective, giving us a deep understanding of the struggles beginners face in the industry.<br/><br/>
                    Despite the ups and downs, the strong bond among our team turned our workplace into a supportive family during challenging periods, fostering resilience and unity. Together, we tackled the uncertainties brought by the COVID-19 pandemic, a time that could have been disastrous. Our collective effort, shared commitment, and the family-like atmosphere helped us not just survive but thrive. Today, with over 100 clients served, including three international and various corporate clients, we stand as proof of the strength that determination, innovation, and the unwavering support of our dedicated team can bring.
                </p>
            </Left>
            <Right>
                <img src={AboutImage} alt="" />
            </Right>
        </Content>
        <Signature>
                <Sign>
                    <img src={Sign1} alt="" />
                    <p>
                        <a>Suprabha Ojha</a><br/>
                        Founder
                    </p>
                </Sign>
                <Sign>
                    <img src={Sign2} alt="" />
                    <p>
                        <a>Devraj Bhandari</a><br/>
                        Founder
                    </p>
                </Sign>
        </Signature>
        <LogoSection>
            <Logo onClick = {()=>{}}/>
        </LogoSection>
        <Contact/>
    </Wrapper>
  )
}

export default AboutPage
import React, { useState } from 'react'
import logo from '../../assets/images/logo/logo.png'
import styled from 'styled-components'
import TopBar from '../topbar/TopBar'
import { useNavigate } from 'react-router-dom'
import Logo from '../logo/Logo'
import NavCollapsed from './NavCollapsed'

const Nav = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.036);
  z-index : 9999;
`
const Wrapper = styled.div`
    margin-top : 10px;
    display : flex;
    align-items : center;
    position : relative;
    transition-delay : 1s;
    animation : fade 1s;

  @keyframes fade {
    0%{
      opacity : 0%;
      transform : translateY(-30px) 
    }
    100%{
      opacity : 100%;
      transform : translateY(0px) 
    }
  }
  @media (max-width: 700px) {
    justify-content : space-between;
  }
`
const NavItems = styled.div`
    color : #212737;
    font-size : 16px;
    ul {
    list-style: none;
    margin: 0;
    display: flex;
  }
  li {
    display: inline-block;
    display: inline;
    margin: 20px;
    position: relative;
    cursor: pointer;
  }
  a {
    padding-bottom: 10px;
    text-decoration: none;
    color : inherit;
    transition: all .5s;
  }
  a:hover {
    color: #B88E72;
  }
  a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #B88E72;
    height: 1px;
    transition: all .3s;
  }
  a:hover:after {
    width: 100%;
  }
  .active{
    color: #B88E72;
  }
  .active:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #B88E72;
    height: 1px;
    transition: all .3s;
  }
  .active:after {
    width: 100%;
  }
  position : fixed;
  right : 0;
  @media (max-width: 700px) {
    display : none;
  }
`
const NavIconCollapsed = styled.div`

`
const Icon = styled.div`
  display : none;
  @media (max-width: 700px) {
    display : initial;
  }
  svg{
    height : 25px;
    width : 30px;
    margin-right : 10px;
    cursor: pointer;
  }
`
const Collapsed = styled.div`
  @media (min-width: 700px) {
    display : none;
  }  
`
function NavBar({currentPage}) {
  const navigate = useNavigate();
  const [nav,setNav] = useState(false);
  const isCurrentPage = (page) => currentPage === page;
  return (
    <>
    <Nav>
        <TopBar/>
        <Wrapper>
            <Logo onClick={()=>navigate("/")}/>
            <NavItems>
                <ul>
                  <li className={isCurrentPage('home') ? 'active' : ''}><a href='/'>Home</a></li>
                  <li className={isCurrentPage('products') ? 'active' : ''}><a href='/products'>Browse Collections</a></li>
                  <li className={isCurrentPage('services') ? 'active' : ''}><a href='/services'>Our Services</a></li>
                  <li className={isCurrentPage('about') ? 'active' : ''}><a href='/about'>About Us</a></li>
                  <li className={isCurrentPage('contact') ? 'active' : ''}><a href='/contact'>Contact</a></li>
                </ul>
            </NavItems>
            <NavIconCollapsed>
              <Icon onClick={()=>{setNav(!nav)}}>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000"
                  preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                  fill="#000000" stroke="none">
                    <path d="M149 2152 c-15 -13 -22 -30 -22 -52 0 -22 7 -39 22 -52 22 -17 69
                    -18 1131 -18 1062 0 1109 1 1131 18 31 26 31 78 0 104 -22 17 -69 18 -1131 18
                    -1062 0 -1109 -1 -1131 -18z"/>
                    <path d="M149 1332 c-15 -13 -22 -30 -22 -52 0 -22 7 -39 22 -52 22 -17 69
                    -18 1131 -18 1062 0 1109 1 1131 18 31 26 31 78 0 104 -22 17 -69 18 -1131 18
                    -1062 0 -1109 -1 -1131 -18z"/>
                    <path d="M149 512 c-15 -13 -22 -30 -22 -52 0 -22 7 -39 22 -52 22 -17 69 -18
                    1131 -18 1062 0 1109 1 1131 18 31 26 31 78 0 104 -22 17 -69 18 -1131 18
                    -1062 0 -1109 -1 -1131 -18z"/>
                  </g>
                </svg>
              </Icon>
            </NavIconCollapsed>
        </Wrapper>
    </Nav>
    <Collapsed>
      <NavCollapsed display={nav} currentPage={currentPage}/>
    </Collapsed>
    
    </>
  )
}

export default NavBar
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import openingImage from '../../assets/images/Home/IMG_9427-2 1-3.png'
import openingImageMobile from '../../assets/images/Home/IMG_9427-2 1-2.png'
import videoGif from '../../assets/images/Home/video.gif'
import ButtonA from '../button/ButtonA'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div`
  background-color : #d9da9815;
  margin-top : 25px;
  display : flex;
  align-items : center;
  justify-content : space-between;
  animation : appear 2s;

  @keyframes appear {
    0%{
      opacity : 0%;
    }
    100%{
      opacity : 100%;
    }
  }
  @media (max-width: 700px) {
    flex-direction : column;
  }
`

const Left = styled.div`
  height : fit-content;
  max-width : 700px;
  margin : 0 auto;
  /* transform : translateY(80px); */
  padding : 10px 15px;  
  vertical-align : center;
  h1{
    font-weight : 100;
  }
  p{
    color: #494949;
  }
  @media (max-width : 1000px) {
    transform : none;
  }
  @media (max-width: 700px) {
    padding-top : 0px;
    order : 2;
    h1{
      width : fit-content;  
      text-align : center;
    }
  }
`
const Right = styled.div`
  /* margin-right : 40px; */
  img{
    height : 680px;
  }
  @media (max-width : 1200px) {
    img{
      max-width : 600px
    }
  }
  @media (max-width: 800px) {
    img{
      /* height : 380px; */
      /* transform: translateY(30px) */
      width : 70vw;
      height : auto;
    }
  }
  @media (max-width: 700px) {
    img{
      width : 70vw;
    }
    width : fit-content;
    margin : 0 auto;
    margin-bottom : 20px;
    order : 1;
  }
  @media (max-width: 500px) {
    img{
      height : 500px;
    }
  }
`

const Buttons = styled.div`
  display : flex;
  margin-top : 30px;
  button:last-child{
    margin-left : 20px;
  }
  @media (max-width: 700px) {
    width : fit-content;
    margin : 10px auto;
  }
`

function Opening() {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isMobileScreen, setIsMobileScreen] = useState(window.matchMedia('(max-width: 900px)').matches);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.matchMedia('(max-width: 900px)').matches);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Wrapper>
      <Left>
          <h1>Dedication to Quality Craftsmanship and Elegant Designs</h1>
          <p>Forall manufacturing, where passion meets fashion. Established in 2020, we emerged from a vision to redefine clothing. With our own factory, we craft resilience, innovation, and style. Our commitment to minimizing MOQ, providing comprehensive support, and fostering a collaborative environment makes us the ideal partner for aspiring entrepreneurs navigating the world of fashion.</p>
          <Buttons>
            <ButtonA text = {"Pick A Sample"} onClick={()=>{navigate("/products")}}/>
            <ButtonA text = {"Get A Quote"} onClick={()=>{navigate("/contact")}}/>
          </Buttons>
      </Left>

      <Right>
        <img src={videoGif} alt=''/>
      {/* <img src={isMobileScreen ? openingImageMobile : openingImage} alt="" /> */}
      
        {/* <video loop muted={true} autoPlay={true} controls=''>
          <source src={video} type="video/mp4"/>
        </video> */}

      </Right>

    </Wrapper>
  )
}

export default Opening
import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import TitleA from '../title/TitleA';
import leftArrow from '../../assets/images/icons/left-arrow.png'
import rightArrow from '../../assets/images/icons/right-arrow.png'
import TitleB from '../title/TitleB';
import { useNavigate } from 'react-router-dom';

const fadeIn = keyframes`
    from {
		opacity: 0;
		transform: translate3d(0, 20%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
`;

const Wrapper = styled.div`
  opacity: 0;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 0.8s forwards;

  .buttons-container{
    display : flex;
    justify-content : space-between;
    position : absolute;
    width: 100%;
  }

  .buttons-container button{
    height : 35px;
    width : 35px;
    border-radius : 50%;
    border : none;
    background-color : #0000008c;
    color : white;
    cursor: pointer;
    z-index : 999;
  }

  .buttons-container button:hover{
    background-color :  #000000c2;
  }

  .buttons-container button img{
    height : 22px;
    margin-top : 4px;
  }
`;

const Slider = styled.div`
  margin-top: 35px;
  display: flex;
  align-items: center;
  overflow-y : scroll;
  scroll-behavior : smooth;

  &&::-webkit-scrollbar {
    display: none !important;
  }
`;


const Cloth = styled.div`
  height: fit-content;
  width: 270px;
  margin-right: 12px;
  cursor: pointer;
  img{
    transition-duration : 0.4s;
  }

  img:hover{
    transform : translateY(-5px) scale(1.01)
  }
`;

const ItemStack = styled.div`
  text-align: right;
  font-size: 12px;
  color: #000000a1;
`;


const Sub = styled.div`
  padding : 0 25px;
  padding-top : 85px;
`

function CollectionSliderFull(props) {
  const [clothes, setClothes] = useState();
  const containerRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;
    setScrollPosition(newScrollPosition);
    containerRef.current.scrollLeft = newScrollPosition;
  };
    useEffect(()=>{
        const fetchClothes = (async()=>{
            const clothes = await props.clothes();
            setClothes(clothes);
        })

        fetchClothes();
    },[props])

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
        <Wrapper ref={ref} inView={inView}>
            {props.title && 
                <TitleA text={props.name} />
            }
            {props.sub && 
                <Sub>
                <TitleB text={props.sub}/>
                </Sub>
            }
        <Slider  ref={containerRef}>
            <div className='buttons-container'>
                <button onClick={() => handleScroll(-400)}> <img src={leftArrow} alt="" /> </button>
                <button onClick={() => handleScroll(400)}> <img src={rightArrow} alt="" /> </button>
            </div>
            {clothes &&
                clothes.map((cloth, index) => {
                const image = Object.values(cloth.image)[0];
                return (
                    <Cloth key={index}>
                    <img src={image} alt="no image" />
                    <ItemStack>
                        <span>{cloth.title}</span>
                    </ItemStack>
                    </Cloth>
                );
            })}
        </Slider>

        </Wrapper>
  );
}

export default CollectionSliderFull;
import React from 'react'
import styled from 'styled-components'

const H = styled.div`
    h1{
      font-size : 42px;
      font-weight : 100;
      color : #212737;
      margin: 50px auto;
      margin-left : 50px;
      @media (max-width: 800px) {
        margin-left : 0;
        text-align : center;
      }
    }
`
function TitleA({text}) {
  return (
    <H><h1>{text}</h1></H>
  )
}

export default TitleA
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import ImageLeather from '../../assets/images/Clothes/slider/image-5.png'
import ImagePashmina from '../../assets/images/Clothes/slider/image-6.png'
import ImageJeans from '../../assets/images/Clothes/slider/image-7.png'
import ImageLoom from '../../assets/images/Clothes/slider/image-8.png'
import { useInView } from 'react-intersection-observer'

const Wrapper = styled.div`
    position : relative;

    @media (max-width : 900px) {
        width : fit-content;
        margin : 10px auto;
    }
`
const Buttons = styled.div`
    width : 100%;
    position : absolute;
    top : 50%;
    display : flex;
    justify-content : space-between;
    z-index : 999;
    button{
        height : 40px;
        width : 40px;
        border-radius : 50%;
        border : 1px solid #212737f2;
        background-color : transparent;
        cursor: pointer;
        transition-duration : 0.4s;
        position : relative;
    }
    button:hover{
        background-color : #212737f2;
    }
`

const Image = styled.div`
    width : fit-content;
    margin : 0 auto;
    position : relative;
    cursor: pointer;
`
const Circle = styled.div`
    position : absolute;    
    height : 20px;
    width : 20px;
    border-radius : 50%;
    top : 9px;
    left : 9px;
    background-color : #212737f2;
`
const Desc = styled.div`
    position : absolute;
    bottom : 27px;
    right : 22px;
    width : 180px;
    height : 50px;
    border-bottom-right-radius : 12px;
    background-color : #212737;
    color : white;
    text-align : center;
    line-height : 50px;
    font-size : 24px;
`
function CollectionSlider() {
    const [image, setImage] = useState();
    const [desc, setDesc] = useState();
    const [itemIndex, setItemIndex] = useState(0);
    const { ref, inView } = useInView({
        triggerOnce: true,
      });

    const items = [[ImageLeather,'Leather'],[ImagePashmina, 'Hemp'],[ImageJeans, 'Denim'],[ImageLoom, 'Hand Loom']];
    useEffect(()=>{
        setImage(items[itemIndex][0])
        setDesc(items[itemIndex][1])
    }, [items, itemIndex])
  return (
    <Wrapper ref={ref} inView ={inView}>
        <Buttons>
            <button onClick={()=>{setItemIndex((itemIndex - 1 + items.length) % items.length)}}>
                <Circle></Circle>
            </button>
            <button onClick={()=>{setItemIndex((itemIndex + 1 + items.length) % items.length)}}>
                <Circle></Circle>
            </button>
        </Buttons>
        <Image>
            <img src={image} alt="" />
            <Desc>
               {desc}
            </Desc>
        </Image>
    </Wrapper>
  )
}

export default CollectionSlider
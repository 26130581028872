import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import TitleA from '../title/TitleA'
import TitleB from '../title/TitleB'
import Paragraph from '../collections/paragraph/Paragraph'
import factoryImage from '../../assets/images/Home/factory-slider-1.png'
import factoryImage2 from '../../assets/images/Home/factory-slider-2.png'
import factoryImage3 from '../../assets/images/Home/factory-slider-3.png'
import factoryImage4 from '../../assets/images/Home/factory-slider-4.png'
import BulletWidget from '../widget/BulletWidget'
import { useInView } from 'react-intersection-observer'

const Wrapper = styled.div`
  opacity: 0;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 2s forwards;
  
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const Text = styled.div`
    max-width : 600px;
    margin-left : 22px;
    @media (max-width: 900px) {
      width : fit-content;
      margin : auto;
    }
`
const Content = styled.div`
    margin : 0 55px;
    display : flex;
    @media (max-width: 900px) {
      flex-direction : column;
    }
    @media (max-width: 500px) {
      margin : 0;
      ${Text}{
        margin : 0 20px;
        h1{
          text-align : center;
        }
      }
    }
`
const Bottom = styled.div`
`
const SlideshowContainer = styled.div`
  width : 575px;
  position: relative;
  margin: auto;
  overflow: hidden;
  img{
    height : 400px;
  }
  @media (max-width: 600px) {
      img{
        /* display : none; */
      }
  }
  @media (max-width: 500px) {
      img{
        width : 600px;
      }
    }
`;

const Slide = styled.div`
  display: flex;
  transition: transform 1.5s ease;
`;
const Picture = styled.div`
  img{
  }
`

const images = [factoryImage, factoryImage2,factoryImage3,factoryImage4]
function OurStory() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);
  return (
    <Wrapper ref={ref} inView={inView}>

        <TitleA text={"OUR STORY AND KEY CREDENTIALS"}/>
        <Content>
            <SlideshowContainer>
              <Slide style={{ transform: `translateX(-${slideIndex * 100}%)` }}>
                {images.map((image, index) => (
                  <Picture key={index}>
                    <img src={image} alt={`Slide ${index + 1}`}/>
                  </Picture>
                ))}
              </Slide>
            </SlideshowContainer>
            <Text>
                <TitleB text={"Why We Started ForAll"}/>
                <Paragraph text={"Forall was founded in 2020 by visionary creators driven by a shared passion to revolutionize the clothing industry. Faced with challenges in finding a manufacturing partner aligned with their unique vision, the founders took the bold step of establishing their own production facility. This decision marked the genesis of ForAll, born out of the desire to bring a distinctive clothing brand to life and address the industry's obstacles with a fresh perspective. The founders sought to create not just a company but a platform for innovation, resilience, and style, where their team could thrive and overcome challenges together. "}/>
            </Text>
        </Content>
        <Bottom>
            <BulletWidget left={"01 ENABLING DREAMERS"} right={"We minimize MOQ, supporting young dreamers aspiring to start a clothing brand, ensuring policies don't shatter their entrepreneurial dreams."}/>
                        <BulletWidget left={"02 EMPLOYEE WELL-BEING"} right={"Our top priority is caring for our workforce, recognizing their pivotal role in our operations."}/>
                        <BulletWidget left={"03 UNWAVERING PRINCIPLES"} right={"We stand firm in our beliefs, unwaveringly committed to integrity, ethics, and our mission."}/>
        </Bottom>
    </Wrapper>
  )
}

export default OurStory
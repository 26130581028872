import React from 'react'
import styled from 'styled-components'
import Contact from '../components/contact/Contact'
import NavBar from '../components/navbar/NavBar'
import TitleA from '../components/title/TitleA'
import BulletWidget from '../components/widget/BulletWidget'

const Wrapper = styled.div`
`
const Padding = styled.div`
    padding-top : 75px;
`
const Title = styled.div`
    max-width : 1100px;
    margin : auto;
`
const Content = styled.div`
    max-width : 1100px;
    margin : auto;
`

function ContactPage() {
  return (
    <Wrapper>
        <NavBar currentPage={"contact"}/>
        <Padding/>
        <Title>
          <TitleA text={"YOUR PRODUCT, IN 3 STEPS"}/>
        </Title>
        <Content>
            <BulletWidget left = {"01"} right={"Discover your favorite styles on our collections page, share the image or apparel name via our contact form, and our team will provide an instant quote with a minimum order quantity in no time"}/>
            <BulletWidget left = {"02"} right={"If you desire a custom design, take the next step by sending an inquiry using the contact form below."}/>
            <BulletWidget left = {"03"} right={"For a more personal touch, visit our office and factory located in Lalitpur, Kathmandu, Nepal. Immerse yourself in the design process and explore endless possibilities for your unique vision."}/>
        </Content>
        <Contact/>
    </Wrapper>
  )
}

export default ContactPage
import React from 'react'
import styled from 'styled-components'
import NavBar from '../components/navbar/NavBar'
import Opening from '../components/opening/Opening'
import Collection from '../components/collections/Collection'
import CollectionSliderFull from '../components/collections/CollectionSliderFull'
import { getCorporate, getCotton, getDesigner, getHemp, getWoolen, getJeans } from '../store/ClothesStore'
import TitleA from '../components/title/TitleA'
import TitleB from '../components/title/TitleB'
import Contact from '../components/contact/Contact'
import OpeningAlternate from '../components/opening/OpeningAlternate'

const Wrapper = styled.div`
`
const Padding = styled.div`
  padding-top : 75px;
`
const More = styled.div`
  h1 {
    margin :  80px 20px;
    text-align : center;
  }
`
function ProductPage() {
  getWoolen();
  return (
    <Wrapper>
        <NavBar currentPage={"products"}/>
        <Padding/>
        <OpeningAlternate/>  
        <CollectionSliderFull sub={"WOOLLEN COLLECTION"} clothes={getWoolen}/>
        <CollectionSliderFull sub ={"COTTON COLLECTION"} clothes={getCotton}/>
        <CollectionSliderFull sub ={"JEANS COLLECTION"} clothes={getJeans}/>
        <CollectionSliderFull sub ={"HEMP COLLECTION"} clothes={getHemp}/>
        <CollectionSliderFull sub ={"CORPORATE COLLECTION"} clothes={getCorporate}/>
        <CollectionSliderFull sub ={"FORALL DESIGNER"} clothes={getDesigner}/>
        <More>
          <TitleB text={"HAVE YOUR OWN DESIGN ? OR NEED A DESIGN FOR YOUR BRAND?"}/>
        </More>
        <Contact/>
    </Wrapper>
  )
}

export default ProductPage
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    font-size : 14px;
    color : #21273780;
    padding-bottom : 50px;
    text-align : right;
    width : fit-content;
    height : fit-content;
    p{
      margin : 0;
      height : fit-content;
    }
`
function Footer() {
  return (
    <Wrapper><p>Copyright © 2024, Forall Manufacturing.  All rights reserved.</p></Wrapper>
  )
}

export default Footer
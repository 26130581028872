import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import openingImage from '../../assets/images/Home/IMG_9427-2 1-3.png'
import openingImageMobile from '../../assets/images/Home/IMG_9427-2 1-2.png'
import ButtonA from '../button/ButtonA'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div`
  background-color : #d9da9815;
  margin-top : 25px;
  display : flex;
  align-items : center;
  justify-content : space-between;
  animation : appear 2s;

  @keyframes appear {
    0%{
      opacity : 0%;
    }
    100%{
      opacity : 100%;
    }
  }
  @media (max-width: 700px) {
    flex-direction : column;
  }
`

const Left = styled.div`
  height : fit-content;
  max-width : 700px;
  margin : 0 auto;
  /* transform : translateY(80px); */
  padding : 10px 15px;  
  vertical-align : center;
  h1{
    font-weight : 100;
  }
  p{
    color: #494949;
  }
  @media (max-width : 1000px) {
    transform : none;
  }
  @media (max-width: 700px) {
    padding-top : 0px;
    order : 2;
    h1{
      width : fit-content;  
      text-align : center;
    }
  }
`
const Right = styled.div`
  img{
    height : 480px;
  }
  @media (max-width : 1200px) {
    img{
      max-width : 600px
    }
  }
  @media (max-width: 800px) {
    img{
      /* height : 380px; */
      /* transform: translateY(30px) */
    }
  }
  @media (max-width: 700px) {
    img{
      height : 480px;
    }
    width : fit-content;
    margin : 0 auto;
    margin-bottom : 20px;
    order : 1;
  }
  @media (max-width: 500px) {
    img{
      height : 350px;
    }
  }
`

const Buttons = styled.div`
  display : flex;
  margin-top : 30px;
  @media (max-width: 700px) {
    width : fit-content;
    margin : 10px auto;
  }
`

function OpeningAlternate() {
  const navigate = useNavigate();
  const [isMobileScreen, setIsMobileScreen] = useState(window.matchMedia('(max-width: 900px)').matches);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.matchMedia('(max-width: 900px)').matches);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Wrapper>
      <Left>
          <h1>Your Style, Our Craft: Unleashing Quality Designs for Your Brand</h1>
          <p>Explore a world of quality designs tailored just for you. Choose from our curated selection or bring your vision to life by contacting us for a personalized design experience. Your unique style, our dedicated craftsmanship – the perfect collaboration for your brand's distinct identity.</p>
          <Buttons>
            <ButtonA text = {"Get A Quote"} onClick={()=>{navigate("/contact")}}/>
          </Buttons>
      </Left>

      <Right>
      <img src={isMobileScreen ? openingImageMobile : openingImage} alt="" />
      </Right>

    </Wrapper>
  )
}

export default OpeningAlternate
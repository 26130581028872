import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.p`
    padding-top : 10px;
    color : #39383893;
`
function Paragraph({text}) {
  return (
    <Wrapper>{text}</Wrapper>
  )
}

export default Paragraph
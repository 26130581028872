import React from 'react'
import styled, { keyframes } from 'styled-components'
import TitleA from '../title/TitleA'
import customerImage from '../../assets/images/Home/customers.png'
import { useInView } from 'react-intersection-observer'

const Wrapper = styled.div`
  opacity: 0;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 2s forwards;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
const Picture = styled.div`
    width : fit-content;
    margin : 0 auto;
    img{
        width  : 100vw;
    }
`
function OurCustomers() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <Wrapper ref={ref} inView={inView}>
        <TitleA text={"OUR CUSTOMERS"}/>
        <Picture>
            <img src= {customerImage} alt="" />
        </Picture>
    </Wrapper>
  )
}

export default OurCustomers
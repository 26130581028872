import React, { useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import TitleA from '../title/TitleA';
import Paragraph from '../collections/paragraph/Paragraph';
import ButtonA from '../button/ButtonA';
import CustomPatternImage from '../../assets/images/Home/sample-5.jpg'
import leftArrow from '../../assets/images/icons/left-arrow.png'
import rightArrow from '../../assets/images/icons/right-arrow.png'
import sample1 from '../../assets/images/Home/misc-1.png'
import sample2 from '../../assets/images/Home/misc-2.png'
import sample3 from '../../assets/images/Home/misc-3.png'
import sample4 from '../../assets/images/Home/misc-4.png'
import sample5 from '../../assets/images/Home/misc-5.png'

const appear = keyframes`
  from{
    opacity : 0%
  }
  to{
    opacity : 100%
  }
`
const Wrapper = styled.div`
  max-width : 1200vw;
  .buttons-container{
    display : flex;
    justify-content : space-between;
    position : absolute;
    width: 100vw;
    margin-top : 280px;
  }

  .buttons-container button{
    height : 35px;
    width : 35px;
    border-radius : 50%;
    border : none;
    background-color : #0000008c;
    color : white;
    cursor: pointer;
    z-index : 999;
  }

  .buttons-container button:hover{
    background-color :  #000000c2;
  }

  .buttons-container button img{
    height : 22px;
    margin-top : 4px;
  }
`;

const Center = styled.div`
  padding: 25px 0px;
  @media (max-width : 700px) {
    h1{
      text-align : left !important;
      margin-left : 20px;
    }
  }
`

const Right = styled.div`
  height : 100%;
  img{
    height : 600px;
  }
  order : 2;
`

const Left = styled.div`
    max-width: 100%;
    margin: 0 60px;
`
const Cotton = styled.div`
  display: flex;
  flex-direction : column;
  @media (max-width : 700px) {
        
        ${Left}{
            order : 2;
            width: fit-content;
            margin-top: 60px;
            margin-left : 30px;
            img{
              
            }
        }
        ${Right}{
            width: 90vw;
            margin : auto;
            order : 1;
            @media (max-width : 700px){
              margin-top : 30px;
            }
        }
    }
`

const Title = styled.div`
    font-size: 28px;
    color: #B88E72;
`
const Subtitle = styled.div`
    font-size: 22px;
    color: #212737;
`
const Images = styled.div`
  display : flex;
  justify-content : space-evenly;
  justify-content : start;
  overflow-y : scroll;
  scroll-behavior : smooth;
  margin-top : 38px;
  background-color : transparent;
  position : relative;
  img{
    margin-right : 20px;
    border : 1px solid #a6a4a45a;
    box-shadow: #a6a4a488 0px 7px 9px 0px;
    max-width : 350px;
  }
  &&::-webkit-scrollbar {
    display: none !important;
  }
  @media (max-width: 1200px) {  
    img {
      width: 100%;  
      margin-bottom: 20px;  
    }
  }
`
const Padding = styled.div`
  padding-top : 75px;
`
const Pattern = styled.div`
  h1{
    margin-left : 100px;
  }
`
function CustomPattern() {
  const containerRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (scrollAmount) => {
    const newScrollPosition = scrollPosition + scrollAmount;
    setScrollPosition(newScrollPosition);
    containerRef.current.scrollLeft = newScrollPosition;
  };
  return (
    <Wrapper>
    <Center>
        <TitleA text={"MORE THAN JUST MANUFACTURING"}/>
    </Center>
    <Cotton>
      <Left>
        <Title>Custom Designs, Physical Patterns,</Title>
        <Subtitle>and more under the same roof</Subtitle>
        <Paragraph text={"At Forall, we specialize in crafting custom physical patterns tailored to your unique style. From design conception to manufacturing and doorstep delivery, our streamlined process ensures a seamless experience. Whether you bring your own design, choose from our curated selection, or collaborate with us to create something entirely new, we've got it all under one roof. Explore the convenience of having diverse design options at your fingertips, making your fashion journey effortless and personalized. Join us in bringing your vision to life, where creativity meets quality, all in one place—Forall."}/>
        
        <Images ref={containerRef}>
          <img src={CustomPatternImage} alt="" />
          <img src={sample5} alt="" />
          <img src={sample1} alt="" />
          <img src={sample2} alt="" />
          <img src={sample3} alt="" />
          <img src={sample4} alt="" />
        </Images>
      </Left>
    </Cotton>
    </Wrapper>
  )
}

export default CustomPattern
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LogoImg from '../assets/images/logo/logo.png'
import Input from '../components/input/Input';
import ButtonB from '../components/button/ButtonB';
import ButtonA from '../components/button/ButtonA';
import Paragraph from '../components/collections/paragraph/Paragraph';
import { getLocalStorage, setLocalStorage } from '../localStorage/contextService';

const Wrapper = styled.div`

`;
const Form = styled.div`
    width : fit-content;
    margin : 40px auto;
    text-align : center;
    p{
        max-width : 500px;
    }
`
const Logo = styled.div`
    
`;

const password = "forall";
function Authorize({next}) {
    const [credential, setcredential] = useState('');
    const [log, setLog] = useState(false);
    const auth = () =>{
        if(credential === password){
            setLocalStorage(true).then(()=>{
                next();
            })
        }
    }
    useEffect(()=>{
        const auth = async() =>{
            const authState = await getLocalStorage("auth");
            if(authState){
               setLog(true);
            }
        }
        auth();
    },[])
    return (
        <>
            {log ? next() :
            <Wrapper>
                <Form>
                    <Logo>
                        <img src={LogoImg} alt="" height='70px'/>
                    </Logo>
                    <Input label = {"Enter Password"} onChange = {(e)=>{setcredential(e.target.value)}}/>
                    <ButtonA text = {"Continue"} onClick = {()=>{auth()}}/>
                    <Paragraph text={"This website is currently under development. Enter the given credential if you are an admin, or contact : contact@forallmanufacturing.com."}/>
                    <Paragraph text={" Developer : saugat@servplotter.com"}/>
                </Form>
            </Wrapper>
            }
            
        </>
    )
}

export default Authorize
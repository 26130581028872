import React from 'react'
import styled from 'styled-components'

const Quote = styled.p`
  color : #313131;
  padding-top : 10px;
  letter-spacing : 1px;
  max-width : 900px; 
  text-align : center;

  b{
    font-weight : 100;
    color : #000000;
  }
`
function Testimonial(props) {
  return (
    <Quote>{props.text} <br /> - <b>{props.author}</b></Quote>
    
  )
}

export default Testimonial
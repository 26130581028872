import React from 'react'
import styled from 'styled-components'
import ButtonA from '../button/ButtonA'
import TitleB from '../title/TitleB'
import Image from '../../assets/images/Home/AboutUsOriginal.png'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div`
  margin-left : 12vw;
  max-width : 50vw;
  button{
    margin-top: 10px;
  }
  @media (max-width: 800px) {
    max-width : 100vw!important;
    margin : 0;
  }
`
const Picture = styled.div`
  @media (max-width: 900px) {
      margin : auto;
      img{
        height : 320px;
      }
  }
  @media (max-width: 600px) {
     width : fit-content;
      img{
        height : 420px;
      }
  }
  @media (max-width: 550px) {
     width : fit-content;
      img{
        height : 320px;
      }
  }
  
`
const Quote = styled.div`
  color : #313131;
  padding-top : 10px;
  letter-spacing : 1px;
`
const Desc = styled.div`
  padding-top : 10px;
  color : #39383878;
`
function AboutUs() {
  const navigate = useNavigate();
  return (
    <Wrapper>
        <Picture>
            <img src={Image} alt="" />
        </Picture>
        <TitleB text = {"Why We Started ForAll"}/>
            
        <Quote>
          “Challenges became opportunities as we, a united family, turned our own factory into a thriving testament to determination, innovation, and unwavering team support.”
        </Quote>
        <Desc>
        Founded by two young and highly driven individuals, our manufacturing company emerged when we sought to establish a clothing brand. Faced with challenges in ...
        </Desc>
        <ButtonA text = {"Learn About Us"} onClick={()=>{navigate("/about")}}/>
    </Wrapper>
  )
}

export default AboutUs
import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    border : none;
    background-color : #212737f2;
    color : #ffffffc9;
    width : 145px;
    height : 42px;
    font-size : 16px;
    cursor: pointer;
    color : #ffffffcf;
    transition-duration : 0.1s;

    &&:hover{
        background-color: #212737;
        color : #ffffff;
    }
    
`
function ButtonA(props) {
  return (
    <Button onClick={props.onClick}>{props.text}</Button>
  )
}

export default ButtonA
import HomePage from "./pages/HomePage.jsx";
import './App.css'
import Router from "./router/Router.jsx";
import { useState } from "react";
import Authorize from "./pages/Authorize.jsx";
function App() {
  const [auth, setAuth] = useState(false);
  const authorize = () =>{
    setAuth(true)
  }
  return (
    // auth ?
    // <Router/>
    // :
    // <Authorize next = {authorize}/>
    <Router/>
  );
}

export default App;

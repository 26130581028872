export async function getWoolen() {
    const woolenImages = [
      'wool-1',
      'wool-2',
      'wool-3',
      'wool-4',
      'wool-5',
      'wool-6',
      'wool-7',
      'wool-8'
    ];
  
    const imagePaths = await Promise.all(
      woolenImages.map(async (imageName) => {
        const module = await import(`../assets/images/Clothes/woolen/${imageName}.png`);
        return { [imageName]: module.default };
      })
    );
  
    return imagePaths.map((image) => ({
      title: '',
      image: image
    }));
}
export async function getCotton() {
    const images = [
      'cotton-1',
      'cotton-2',
      'cotton-3',
      'cotton-4',
      'cotton-5',
      'cotton-6',
      'cotton-7',
      'cotton-8',
      'cotton-9',
      'cotton-10',
      'cotton-11',
      'cotton-12'
    ];
  
    const imagePaths = await Promise.all(
      images.map(async (imageName) => {
        const module = await import(`../assets/images/Clothes/cotton/${imageName}.png`);
        return { [imageName]: module.default };
      })
    );
  
    return imagePaths.map((image) => ({
      title: '',
      image: image
    }));
}
export async function getJeans() {
    const images = [
      'jeans-1',
      'jeans-2',
      'jeans-3',
      'jeans-4',
      'jeans-5',
      'jeans-6',
      'jeans-7',
    ];
  
    const imagePaths = await Promise.all(
      images.map(async (imageName) => {
        const module = await import(`../assets/images/Clothes/jeans/${imageName}.png`);
        return { [imageName]: module.default };
      })
    );
  
    return imagePaths.map((image) => ({
      title: '',
      image: image
    }));
}
export async function getCorporate() {
    const images = [
      'corporate-1',
      'corporate-2',
      'corporate-3',
      'corporate-4',
      'corporate-5',
    ];
  
    const imagePaths = await Promise.all(
      images.map(async (imageName) => {
        const module = await import(`../assets/images/Clothes/corporate/${imageName}.png`);
        return { [imageName]: module.default };
      })
    );
  
    return imagePaths.map((image) => ({
      title: '',
      image: image
    }));
}
export async function getHemp() {
    const images = [
      'hemp-1',
      'hemp-2',
      'hemp-3',
      'hemp-4',
      'hemp-5',
      'hemp-6',
      'hemp-7',
    ];
  
    const imagePaths = await Promise.all(
      images.map(async (imageName) => {
        const module = await import(`../assets/images/Clothes/hemp/${imageName}.png`);
        return { [imageName]: module.default };
      })
    );
  
    return imagePaths.map((image) => ({
      title: '',
      image: image
    }));
}
export async function getDesigner() {
    const images = [
      'designer-1',
      'designer-2',
      'designer-3',
      'designer-4',
      'designer-5',
      'designer-6',
    ];
  
    const imagePaths = await Promise.all(
      images.map(async (imageName) => {
        const module = await import(`../assets/images/Clothes/designer/${imageName}.png`);
        return { [imageName]: module.default };
      })
    );
  
    return imagePaths.map((image) => ({
      title: '',
      image: image
    }));
}


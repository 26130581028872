import React from 'react'
import styled, { keyframes } from 'styled-components'
import TitleA from '../title/TitleA'
import Testimonial from '../widget/Testimonial'
import { useInView } from 'react-intersection-observer'

const Wrapper = styled.div`
  opacity: 0;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 2s forwards;
`
const Content = styled.div`
    width : fit-content;
    margin : 20px auto;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
function CustomerTestimonial() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
    <Wrapper ref={ref} inView={inView}>
        <TitleA text={"Client Testimonials"}/>
        <Content>
            <Testimonial text={`"I am truly touched by Forall's commitment to providing opportunities for survivors of domestic violence. Witnessing the resilience and determination of their factory employees, who have gained valuable skills through empowerment and training, is nothing short of inspiring. Our collaboration with ForAll has been more than just a business partnership; it's a meaningful contribution to a cause we deeply believe in. Kudos to ForAll for making a positive impact on lives while delivering exceptional results."`} author={"Company A"}/>
            <Testimonial text={`"Forall is amazing! They make great stuff, always on time. Nice people too. Loved working with them, and everything turned out just right."`} author={"Company A"}/> 
            <Testimonial text={`"Everyone at Forall provided an outstanding customer experience. Their personal attention, genuine interest, and innovative solutions made our collaboration exceptional. It felt like a partnership built on trust and shared values."`} author={"Company A"}/> 
        </Content>
        
    </Wrapper>
  )
}

export default CustomerTestimonial
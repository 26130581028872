import React from 'react'
import NavBar from '../components/navbar/NavBar'
import styled, { keyframes } from 'styled-components'
import TitleA from '../components/title/TitleA';
import CottonImage from '../assets/images/Home/cotton-2.png'
import Paragraph from '../components/collections/paragraph/Paragraph';
import ButtonA from '../components/button/ButtonA';
import CottonSample1 from '../assets/images/Home/cotton-sample1.png'
import CottonSample2 from '../assets/images/Home/cotton-sample2.png'
import CottonSample3 from '../assets/images/Home/cotton-sample3.png'
import CottonSample4 from '../assets/images/Home/cotton-sample4.png'
import FabricOptions from '../components/section/FabricOptions';
import CustomPattern from '../components/section/CustomPattern';
import Contact from '../components/contact/Contact'
import Footer from '../components/section/Footer';

const appear = keyframes`
  from{
    opacity : 0%
  }
  to{
    opacity : 100%
  }
`
const Wrapper = styled.div`
  /* animation : appear 1s; */
`;

const Center = styled.div`
  padding: 25px 0px;
  @media (max-width : 700px) {
    h1{
      text-align : left !important;
      margin-left : 20px;
    }
  }
`

const Left = styled.div`
  height : 100%;
  img{
    height : 600px;
  }
`

const Right = styled.div`
    max-width: 900px;
    margin-left: 60px;
`
const Cotton = styled.div`
  display: flex;
  @media (max-width : 700px) {
        flex-direction : column;
        ${Left}{
            order : 1;
            width: fit-content;
            /* margin : auto; */
            margin-left : 20px;
            img{
              
            }
        }
        ${Right}{
            width: 90vw;
            margin : auto;
            order : 2;
            @media (max-width : 700px){
              margin-top : 30px;
            }
        }
    }
`

const Title = styled.div`
    font-size: 28px;
    color: #B88E72;
`
const Subtitle = styled.div`
    font-size: 22px;
    color: #212737;
`
const Images = styled.div`
  display : flex;
  justify-content : space-evenly;
  justify-content : start;
  overflow-y : scroll;
  scroll-behavior : smooth;
  margin-top : 38px;
  background-color : transparent;
  img{
    margin-right : 20px;
    border : 1px solid #a6a4a45a;
    box-shadow: #a6a4a488 0px 7px 9px 0px;
  }
  &&::-webkit-scrollbar {
    display: none !important;
  }
  @media (max-width: 1200px) {  
    img {
      width: 100%;  
      margin-bottom: 20px;  
    }
  }
`
const Padding = styled.div`
  padding-top : 75px;
`
const Pattern = styled.div`
  h1{
    margin-left : 100px;
  }
`
function OurServicesPage() {
  return (
    <Wrapper>
    <NavBar currentPage={"services"}/>
    <Padding/>
    <Center>
        <TitleA text={"OUR SERVICES"}/>
    </Center>
    <Cotton>
      <Left>
        <img src={CottonImage} alt="" />
      </Left>
      <Right>
        <Title>Cotton Fabric,</Title>
        <Subtitle>customized in your print</Subtitle>
        <Paragraph text={"Discover personalized perfection with our custom print on cotton services. Whether you bring your own designs or entrust us to create one that complements your style, we ensure top-notch quality. Elevate your fabric with bespoke patterns and prints that reflect your unique taste. Unleash creativity and make a statement with our tailored printing services on premium cotton."}/>
        <ButtonA text={"See The Process"}/>
        <Images>
          <img src={CottonSample1} alt="" />
          <img src={CottonSample2} alt="" />
          <img src={CottonSample3} alt="" />
          <img src={CottonSample4} alt="" />
        </Images>
      </Right>
    </Cotton>
    <FabricOptions/>
    <Pattern>
    </Pattern>
    <CustomPattern/> 
    <Contact/>
    </Wrapper>
  )
}

export default OurServicesPage
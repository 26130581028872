import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fadeIn } from '../animations/FadeIn';
import { useInView } from 'react-intersection-observer';

const Wrapper = styled.div`
    color : #212737;
    font-size : 20px;
    ul{
      display : flex;
      width  : fit-content;
      margin : 100px auto;
      text-align : center;
      padding : 0;
    }
    @media (max-width: 700px) {
      width : fit-content;
      margin : 100px auto;
    }
    animation: ${({ inView }) => (inView ? fadeIn : 'none')} 1s forwards;
  `;
const Stat = styled.div`
    margin : 0 15px;
    line-height : 160%;
    padding : 0;
    width : fit-content;
    span{
      
    }
    span:last-child{
      font-size : 24px;
    }
    @media (max-width: 700px) {
      span{
        font-size : 16px;
      }
      span:last-child{
        font-size : 20px;
      }

    }
`;

const AnimatedNumber = ({ value, inView }) => {
  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    let startValue = 0;
    const step = 1;
    const duration = 1000;

    const interval = setInterval(() => {
      startValue += step;

      if (startValue >= value) {
        setAnimatedValue(value);
        clearInterval(interval);
      } else {
        setAnimatedValue(startValue);
      }
    }, duration / (value / step));

    if (!inView) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [value, inView]);

  return <span>{animatedValue}</span>;
};

function Statistics() {
  const {inView, ref} = useInView({
    triggerOnce : true
  })
  return (
    <Wrapper inView = {inView} ref={ref}>
        <ul>
            <Stat>
                <span>Clients</span><br/>
                <span>{<AnimatedNumber value={100} inView= {inView}/>}+</span>
            </Stat>
            <Stat>
                <span>Launched</span><br/>
                <span><AnimatedNumber value={160} inView= {inView}/>+</span>
            </Stat>
            <Stat>
                <span>Satisfaction</span><br/>
                <span><AnimatedNumber value={100} inView= {inView}/>%</span>
            </Stat>
            <Stat>
                <span>Following</span><br/>
                <span><AnimatedNumber value={2.5} inView= {inView}/>k</span>
            </Stat>
        </ul>
    </Wrapper>
  )
}

export default Statistics
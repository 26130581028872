import React, { useState, useEffect } from 'react';
import LhamuSherpa from '../../assets/images/Home/LhamuSherpa.png'
import ChameliMagranti from '../../assets/images/Home/chameli-magranti.png'
import SabinaTamang from '../../assets/images/Home/sabina-tamang.png'
import styled from 'styled-components';

const CarouselContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  max-width : 900px;
`;

const QuoteContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  letter-spacing : 1px;
  color : #313131e5;
  small{
    color : black;
  }
`;

const QuoteCard = styled.div`
  flex: 0 0 100%;
  box-sizing: border-box;
  padding: 20px;
  margin-right: 10px;
`;
const Text = styled.div`
  
`
const Container = styled.div`
  display : flex;
  img{
    height : 300px;
    padding-right : 40px;
  }
  @media (max-width: 800px) {
    flex-direction : column;
    img{
      width : 350px;
      height : auto;
      margin : auto;
    }
  }  
`

function QuoteCarousel () {
  const quotes = [{text :"For me, all the hard work has been a journey. Living in an organization, providing for my family, getting married at 16 and now separated. I was married to a man who deceived me, claiming he was unmarried. I got pregnant at 16, and he disappeared, back to his first wife without any trace. Thankfully, an organization came to my rescue. They helped me through childbirth and provided training for livelihood. I learned stitching and received further training at Forall manufacturing company, securing a job. Now, at 19, I'm a mother of one and the sole provider for my family.",author:"- Sabina Tamang",image:SabinaTamang},
                  {text :"I was married at 17 and gave birth to my first daughter when I was 18. My husband was an alcoholic and abusive. Despite the difficulties, I had another daughter at 23. Eventually, I had to leave the house when my second daughter was only 15 months old. I used to work as labor in construction sites, carrying bricks while taking care of my daughters. Thankfully, an organization came to my rescue. They taught me how to stitch and provided training through Forall, eventually helping me secure a job. Even though I may not have everything materially, at least I am free from torture and find peace in my life now.",author:"- Chameli Magranti",image:ChameliMagranti},
                  {text :"I was married at 14 by my family. He was always away from the family. I gave birth to a daughter at 17. Learnt how to stitch. Tried my hand on a few working place where I never got paid for my job and as a single woman it was very difficult to raise my voice against it. After joining Forall I have everything. I am confident. I feel like I can achieve everything.",author:"- Lamu Sherpa",image:LhamuSherpa}]
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000); 

    return () => clearInterval(interval);
  }, [quotes.length]);

  return (
    <CarouselContainer>
      <QuoteContainer style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {quotes.map((quote, index) => (
          <QuoteCard key={index}>
            <Container>
              <img src={quote.image} alt="" />
              <Text>
                <p>"{quote.text}"</p>
                <small>{quote.author}</small>
              </Text>
            </Container>
            
            
          </QuoteCard>
        ))}
      </QuoteContainer>
    </CarouselContainer>
  );
};

export default QuoteCarousel;

import React from 'react'
import styled from 'styled-components'

const Top = styled.div`
  color : #FFFFFE;
  font-size : 14;
  width : 100%;
  background-color : #B88E72;
  text-align : right;
  a{
    height : 24px;
    margin : auto 10px;
  }
  @media (max-width : 500px) {
    a{
      font-size : 14px;
      text-align : center;
    }
  }
`

function TopBar() {
  return (
    <Top>
      <a>+977-9860074126</a>
      <a>contact@forallmanufacturing.com</a>
    </Top>
  )
}

export default TopBar;
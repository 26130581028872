import React from 'react'
import {BrowserRouter, Routes , Route}  from 'react-router-dom'
import HomePage from '../pages/HomePage'
import AboutPage from '../pages/AboutPage'
import ProductPage from '../pages/ProductPage'
import Contact from '../components/contact/Contact'
import ContactPage from '../pages/ContactPage'
import OurServicesPage from '../pages/OurServicesPage'
import ScrollToTop from '../components/scroll/ScrollToTop'

function Router() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
        <Routes>
            <Route path='' element={<HomePage/>}/>
            <Route path='/' element={<HomePage/>}/>
            <Route path='/home' element={<HomePage/>}/>
            <Route path='/about' element={<AboutPage/>}/>
            <Route path='/products' element={<ProductPage/>}/>
            <Route path='/contact' element={<ContactPage/>}/>
            <Route path='/services' element={<OurServicesPage/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default Router
import React from 'react'
import styled from 'styled-components'
import FabricImage from '../../assets/images/Home/fabrics.png'
import Fabric from '../../assets/images/Home/fabric.png'
import ButtonA from '../button/ButtonA'
import TitleA from '../title/TitleA'
import { useNavigate } from 'react-router-dom'
import { Appear } from '../animations/Appear'
import { InView, useInView } from 'react-intersection-observer'

const Wrapper = styled.div`

  padding : 5px;
  animation : appear 2s;
  background-color : #fbf6f1;
  animation: ${({ inView }) => (inView ? Appear : 'none')} 2s forwards;
`
const Container = styled.div`
  margin-left : 50px;
  display : flex;
  justify-content : space-between;
  align-items : center;
  @media (max-width: 800px) {
    flex-direction : column;
    margin : auto 10px;
  }
`
const Left = styled.div`
  height : fit-content;
  max-width : 700px;
  padding : 10px 15px;  
  padding-left : 0; 
  vertical-align : center;
  h1{
    font-weight : 100;
    margin-left : 0;
  }
  p{
    color: #494949;
  }
  @media (max-width : 1000px) {
    transform : none;
  }
  @media (max-width: 800px) {
    padding-top : 0px;
    order : 2;
    text-align : center;
    width : 90vw;
    margin-left : auto;
    h1{
      width : 100%;  
      text-align : center;
    }
    p{
      text-align : left;
      margin-left : 10px;
    }
    button{
      margin : 20px auto;
      
    }
  }
`
const Right = styled.div`
  position : relative;
  img{
    max-width : 500px;
  }

  @media (max-width: 700px) {
    img{
      height : 550px;
    }
    width : fit-content;
    /* margin-bottom : 20px; */
    padding-top : 20px;
    order : 1;
  }
  @media (max-width : 550px) {
    img{
      height : 400px;
    }
  }
`
function OurProduction() {
  const navigate = useNavigate();
  const {ref, inView} = useInView({
    triggerOnce : true
  })
  return (
    <Wrapper ref={ref} inView={inView}>
      <Container>
        <Left>
            <TitleA text={"OUR OWN PRODUCTION"}/>
            <p>Our highly skilled professionals, trained for excellence, use premium materials from trusted vendors in Nepal and India. We offer comprehensive manufacturing services, ensuring quality from raw materials to final production. Tailoring our approach to your needs, we create unique design patterns for every project. At Forall, we are not just manufacturers but dedicated partners, committed to delivering top-notch products that exceed expectations and reflect our unwavering commitment to excellence.</p>
              <ButtonA text = {"Our Capabilities"} onClick={()=>{navigate("/services")}}/>
        </Left>

        <Right>
            <img src={FabricImage} alt="" />
        </Right>
      </Container>

    </Wrapper>
  )
}

export default OurProduction
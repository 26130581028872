import React from 'react'
import styled, { keyframes } from 'styled-components'
import TitleA from '../title/TitleA'
import Paragraph from '../collections/paragraph/Paragraph'
import ButtonA from '../button/ButtonA'
import CollectionSlider from '../collections/CollectionSlider'
import { useInView } from 'react-intersection-observer'

const fadeIn = keyframes`
    from {
		opacity: 0;
		transform: translate3d(0, 20%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

const Wrapper = styled.div`
    margin : 120px;
    display : flex;
    align-items : center;
    opacity : 0;
    animation: ${({ inView }) => (inView ? fadeIn : 'none')} 1s forwards;
    @media (max-width : 900px) {
        flex-direction : column;
        margin : 120px auto;
    }
`
const Left = styled.div`
    h1{
        margin : 0;
    }
    p{
        margin-bottom : 20px;
    }
    max-width : 350px;
    @media (max-width : 900px) {
        text-align : center;
    }
`
const Quote = styled.div`
    margin : 20px 0;
`
const Right = styled.div`
    width : 100%;
`
const Title = styled.div`
    font-size: 28px;
    color: #B88E72;
`
const Subtitle = styled.div`
    font-size: 22px;
    color: #212737;
`
const Slider = styled.div`
    width : 80%;
    margin : 0 auto;

    @media (max-width : 900px) {
        width : fit-content;
        margin : auto;
        img{
            width : 400px;
        }
    }
`

function FabricOptions() {
    const {ref, inView} = useInView({
        triggerOnce : true
    })
  return (
    <Wrapper ref={ref} inView ={inView}>
        <Left>
        <Title>Fabric Options,</Title>
        <Subtitle>forall your designs</Subtitle>
            <Quote>
                <TitleA text={"Your Style, "}/>
                <TitleA text={"Our Fabric"}/>
            </Quote>
            <Paragraph text={"Discover fabric options for your designs. Our carefully selected materials are crafted to enhance your creations, ensuring quality and a touch of class."}/>
            <ButtonA text = {"ORDER NOW "} onClick = {()=>{}}/>
        </Left>
        <Right>
            <Slider>
                <CollectionSlider/>
            </Slider>
        </Right>
    </Wrapper>
  )
}

export default FabricOptions
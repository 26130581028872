import React from 'react'
import styled from 'styled-components'
import Paragraph from '../collections/paragraph/Paragraph'

const Wrapper = styled.div`
    margin :0 100px;
    animation : appear 2s;
    @keyframes appear {
        0%{
            opacity : 0%;
        }
        100%{
            opacity : 100%
        }
    }
`
const Content = styled.div`
    display : flex;
    align-items : center;
    justify-content : space-evenly;
    @media (max-width: 500px) {
        flex-direction : column;
        text-align : center;
    }
`
const Left = styled.div`
    font-size : 24px;
    min-width : 200px;
    width : 450px;
`
const Right = styled.div`
    max-width : 600px;
    margin-left : 10%;
`
function BulletWidget(props) {
  return (
    <Wrapper>
        <Content>
            <Left>
                {props.left}
            </Left>
            <Right>
                <Paragraph text={props.right}/>
            </Right>
        </Content>
    </Wrapper>
  )
}

export default BulletWidget
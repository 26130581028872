import React from 'react'
import styled from 'styled-components'
import TopBar from '../components/topbar/TopBar'
import NavBar from '../components/navbar/NavBar'
import Opening from '../components/opening/Opening'
import Statistics from '../components/stats/Statistics'
import Collection from '../components/collections/Collection'
import OurVision from '../components/opening/OurVision'
import OurStory from '../components/section/OurStory'
import EmployeeTestimonial from '../components/section/EmployeeTestimonial'
import OurCustomers from '../components/section/OurCustomers'
import CustomerTestimonial from '../components/section/CustomerTestimonial'
import Contact from '../components/contact/Contact'
import Footer from '../components/section/Footer'
import OurProduction from '../components/section/OurProduction'
import NavCollapsed from '../components/navbar/NavCollapsed'
import EmployeeSlider from '../components/widget/EmployeeSlider'

const Padding = styled.div`
  padding-top : 75px;
`
function HomePage() {
  return (
    <>
      {/* <EmployeeSlider/> */}
      <NavBar currentPage={"home"}/>
      <Padding/>
      <NavCollapsed/>
      <Opening/>
      <Statistics/>
      <Collection title = {"AVAILABLE COLLECTIONS"}/>
      <Padding/>
      <OurProduction/>
      <Padding/>
      <OurVision/>
      <Padding/>
      <OurStory/>
      <EmployeeTestimonial/>
      {/* <OurCustomers/> */}
      <CustomerTestimonial/>
      <Contact/>
    </>
    
  )
}

export default HomePage
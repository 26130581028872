import React from 'react'
import styled from 'styled-components'
import ButtonB from '../button/ButtonB'

const Wrapper = styled.div`
  color : #FFFFFF;
  background-color : #212737;
  height : fit-content;
  width : 330px;
  @media (max-width: 800px) {
    order : 2;
    margin-top : 40px;
    width : fit-content;
  }
  button{
    margin :20px 30px;
  }
  p{
    margin : 0 30px;
  }
`
const Title = styled.div`
  margin : 20px 30px;
  font-size : 32px;
  font-family : 100;
`
function ContactWidget() {
  return (
    <Wrapper>
      <Title>REDEFINE YOUR WARDROBE EXPERIENCE</Title>
      <p>Find Classic Elegance at Our Exclusive E-commerce Clothing Spot</p>
      <ButtonB text={"SHOP NOW"} onClick={()=>{}}/>
    </Wrapper>
  )
}

export default ContactWidget
import React, { useState } from 'react'
import styled from 'styled-components'

const Nav = styled.div`

`
const Wrapper = styled.div`
`
const NavItems = styled.div`
    color : #212737;
    font-size : 16px;
    background-color : #fbfbf6;
    height : 100%;
    width : 100%;
    z-index : 9999;
    margin-top : 95px;
    text-align : right;
    animation : appear 0.8s !important;
    @keyframes appear {
        from{
            opacity : 0;
            transform : translateX(150px)
        }
        to{
            opacity : 100%
        }
    }
    ul {
        list-style: none;
        margin: 20px 0;
        display: flex;
        flex-direction : column;
    }
    li {
        display: inline-block;
        display: inline;
        margin: 20px;
        position: relative;
        cursor: pointer;
    }
    a {
        padding-bottom: 10px;
        text-decoration: none;
        color : inherit;
        transition: all .5s;
    }
    a:hover {
        color: #B88E72;
    }
    a:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: '.';
        color: transparent;
        background: #B88E72;
        height: 1px;
        transition: all .3s;
    }
    a:hover:after {
        width: 100%;
    }
    .active{
        color: #B88E72;
    }
    .active:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        content: '.';
        color: transparent;
        background: #B88E72;
        height: 1px;
        transition: all .3s;
    }
    .active:after {
        width: 100%;
    }
    position : fixed;
    right : 0;
`
function NavCollapsed({display},{currentPage}) {
    const isCurrentPage = (page) => currentPage === page;
  return (
    <Nav style={display? {display : 'initial'}: {display:'none'}}>
        <Wrapper>
            <NavItems>
            <ul>
                  <li className={isCurrentPage('home') ? 'active' : ''}><a href='/'>Home</a></li>
                  <li className={isCurrentPage('products') ? 'active' : ''}><a href='/products'>Browse Collections</a></li>
                  <li className={isCurrentPage('services') ? 'active' : ''}><a href='/services'>Our Services</a></li>
                  <li className={isCurrentPage('about') ? 'active' : ''}><a href='/about'>About Us</a></li>
                  <li className={isCurrentPage('contact') ? 'active' : ''}><a href='/contact'>Contact</a></li>
                </ul>
            </NavItems>
        </Wrapper>
    </Nav>
  )
}

export default NavCollapsed
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logo/logo.png'
import styled from 'styled-components'

const Wrapper = styled.div`
    cursor: pointer;
    margin-left : 12px;
    img{
        height : 60px;
    }
`
function Logo(props) {
    return (
        <Wrapper onClick={()=>{props.onClick()}}>
            <img src={logo} alt=""/>
        </Wrapper>
    )
}

export default Logo
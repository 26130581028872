import React from 'react'
import styled, { keyframes } from 'styled-components'
import TitleA from '../title/TitleA'
import ContactWidget from '../widget/ContactWidget'
import AboutUs from '../section/AboutUs'
import { useInView } from 'react-intersection-observer'

const fadeIn = keyframes`
  from {
		opacity: 0;
		transform: translate3d(0, 20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
`;

const Wrapper = styled.div`
  margin-top: 60px;
  opacity: 0;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 1.5s forwards;
`;

const Content = styled.div`
  margin: 0 50px;
  display: flex;
  @media (max-width: 800px) {
      flex-direction : column;
  }
`;

function OurVision() {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <Wrapper ref={ref} inView={inView}>
      <TitleA text={"OUR VISION"} />
      <Content>
        <ContactWidget />
        <AboutUs />
      </Content>
    </Wrapper>
  );
}

export default OurVision;
import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
   font-weight : 100;
   font-size : 26px;
`
function TitleB({text}) {
  return (
    <Title>{text}</Title>
  )
}

export default TitleB